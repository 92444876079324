import React from "react";
import {Badge, ButtonGroup, Dropdown} from "react-bootstrap";
import TagDisplay from "./TagDisplay";

interface DefinitionProps {
    term: any
    definition: any
    display?: any
    renames?: any[]
    redefinition?: any[]
    abbreviation?: any
    tags?: any[]

    isInvisibleTags?: boolean

    isTodo?: boolean
}

const Definition: React.FC<DefinitionProps> = (props) => {
    return (
        <div>
            <b style={{
                backgroundColor: props.isTodo ? "rgba(147,197,255,0.82)" : undefined,
                borderRadius: "8px",
            }}>
                Определение
            </b>
            {". "}
            {!props.isInvisibleTags && props.tags && (
                <TagDisplay tags={props.tags}/>
            )}
            <i>
                {props.term}
                {" "}
                {props.abbreviation != null && (
                    <span>
                    ({props.abbreviation})
                </span>
                )}
            </i>
            {" - "}
            {props.definition}.
            {" "}
            {props.display && (
                <span><u>Отображение</u>: {props.display}</span>
            )}
            {" "}
            <br/>
            {props.renames && props.renames.length > 0 &&
                (
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle variant="Link" id="dropdown-basic" size="sm">
                            <u>Альтернативные названия</u>:
                            <Badge bg="secondary">{props.renames?.length}</Badge>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {props.renames?.map((value, index) => (
                                <Dropdown.Item eventKey={index}
                                               style={{
                                                   fontSize: '14px',
                                                   lineHeight: '1',
                                                   whiteSpace: 'normal',
                                                   wordBreak: 'break-word',
                                               }}
                                >
                                    {value}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
            {
                props.redefinition && props.redefinition.length > 0 &&
                (
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle variant="Link" id="dropdown-basic" size="sm"
                        >
                            <u>Альтернативные определения</u>:
                            <Badge bg="secondary">{props.redefinition?.length}</Badge>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            style={{width: 'auto'}}
                        >
                            {props.redefinition?.map((value, index) => (
                                <Dropdown.Item
                                    eventKey={index}
                                    style={{
                                        fontSize: '14px',
                                        lineHeight: '1',
                                        whiteSpace: 'normal',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    <i>{props.term}</i> - {value}.
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        </div>
    );
}

export default Definition;