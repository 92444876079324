import React from "react";
import Lecture from "../components/Lecture";
import Definition from "../mathobjects/Definition";
import Enumeration from "../mathobjects/Enumeration";
import Statement from "../mathobjects/Statement";
import Algorithm from "../mathobjects/Algorithm";
import Display from "../mathobjects/Display";
import Task from "../mathobjects/Task";

interface Lecture {
    number: string
    name: string
    items: any[]
}

const Databases4SemesterLectures: React.FC = () => {

    const data: Lecture[] = [
        {
            number: "1",
            name: "Функциональные зависимости и Нормальные Формы",
            items: [
                <Definition
                    term={"Атрибут"}
                    definition={"свойство некоторой сущности"}
                    renames={["Поле таблицы"]}
                />,
                <Definition
                    term={"Домен атрибута"}
                    definition={"множество допустимых значений, которые может принимать атрибут"}
                />,
                <Definition
                    term={"Кортеж"}
                    definition={"конечное множество взаимосвязанных допустимых значений атрибутов, которые вместе описывают некоторую сущность"}
                    renames={["Строка таблицы"]}
                />,
                <Definition
                    term={"Отношение"}
                    definition={"конечное множество кортежей"}
                    renames={["Таблица"]}
                />,
                <Definition
                    term={"Схема отношения"}
                    definition={"конечное множество атрибутов, определяющих некоторую сущность"}
                    redefinition={["структура таблицы, состоящей из конкретного набора полей"]}
                />,
                <Definition
                    term={"Аномалия"}
                    definition={"ситуация в таблице, которая приводит к притиворечию либо существенно усложняет обработку"}
                />,
                <Statement
                    name={"Причина аномалии"}
                    statement={"Дублирование данных в таблице, которое вызывается наличием функциональных зависимостей неключевых атрибутов"}
                />,
                <Enumeration
                    name={"Виды аномалий"}
                    items={[
                        <Definition term={"Аномалия модификации"}
                                    definition={"аномалия, где изменение одних данных может повлеч пересмотр всей таблицы и соответствующее изменение некоторых записей таблицы"}/>,
                        <Definition term={"Аномалия удаления"}
                                    definition={"аномалия, где при удаление какого либо кортежа из таблицы может пропасть информация, которая не связана на прямую с удаляемой записью"}/>,
                        <Definition term={"Аномалия добавления"}
                                    definition={"аномалия, которая возникает, когда информацию в таблиук нельзя поместить, пока она не полная, либо вставка записи требует дополнительного просмотра таблицы"}/>,
                    ]}
                />,
                <Definition
                    term={"Нормальная форма"}
                    abbreviation={"НФ"}
                    definition={"требование, предъявляемое к структуре таблиц в теории реляционнах баз данных для устранения из базы избыточных функциональных зависимостей между атрибутами"}
                />,
                <Algorithm
                    name={"Метод нормальнох форм"}
                    items={[
                        "Сбор информации об объектах решения задачи в рамках одного отношения",
                        "Декомпозиция этого отношения не несколько взаимосвязанных отношений на основе процедур нормализации"
                    ]}
                />,
                <Statement
                    name={"Цель нормализации"}
                    statement={"исключить избыточное дублирование данных, которое является причиной аномалий, возникающих при добавлении, редактировании и удалении кортежей"}
                />,
                <Definition
                    term={"Декомпозиция без потерь"}
                    definition={"декомпозиция, в разельтате которой имеется возможность собрать исходное отношение из декомпозированных отношений без потери информации"}
                />,
                <Definition
                    term={"Отношение декомпозируемое n раз"}
                    definition={"отношение, которое может быть декомпозировано без потерь на n проекций"}
                />,
                <Definition
                    term={"Отношение первой нормальной формы"}
                    abbreviation={"Отношение 1НФ"}
                    definition={"отнощение, где все атрибуты являются простыми, все используемые домены должны содержать только сколярные значения и не должно быть повторений строк в таблице"}
                />,
                <Definition
                    term={"Отношение второй нормальной формы"}
                    abbreviation={"Отношение 2НФ"}
                    definition={"отношение, которое находится в 1НФ и каждый не ключевой атрибут неприводимо зависит от первичного ключа"}
                />,
                <Definition
                    term={"Отношение третий нормальной формы"}
                    abbreviation={"Отношение 3НФ"}
                    definition={"отношение, которое находится во 2НФ, и в котором отсутствуют транзитивные функциональные зависимости неключевых атрибутов от ключевых"}
                />,
                <Definition
                    term={"Отношение нормальной формы Бойса-Кодда"}
                    abbreviation={"Отношение НФБК"}
                    definition={"отношение, где каждая её нетривиальная и неприводимая слева функциональная зависимость имеет в качестве своего детерминанта некоторый потенциальный ключ"}
                    renames={["Отношение усиленной третьей нормальной формы"]}
                />,
                <Definition
                    term={"Отношение четвёртой нормальной формы"}
                    abbreviation={"Отношение 4НФ"}
                    definition={"отношение, которое находится в НФБК и не содержит нетривиальных многозначных зависимостей"}
                />,
                <Definition
                    term={"Отношение пятой нормальной формы"}
                    abbreviation={"Отношение 5НФ"}
                    definition={"отнощение, которое находится в 4НФ, и в котором отсутствуют сложные зависимые соединения между атрибутами"}
                />,
                <Definition
                    term={"Отношение доменно-ключевевой нормальной формы"}
                    abbreviation={"ДКНФ"}
                    definition={"отношение, в котором каждое наложенное на неё ограничение является логическим следствием ограничений доменов и ограничений ключей, каложенных на данную переменную отношения"}
                />,
                <Definition
                    term={"Ограничение домена"}
                    definition={"ограничение, предписывающее использовать для определённого атрибута значения только из некоторого заданного домена."}
                />,
                <Definition
                    term={"Ограничение ключа"}
                    definition={"ограничение, утверждающее, что некоторый атрибут или комбинация атрибутов является потенциальным ключом"}
                />,
                <Statement
                    statement={"Любое отношение ДКНФ, обязательно находится в 5НФ. Однака не любое отношения можно привести к ДКНФ"}
                />,
                <Definition
                    term={"Отношение шестой нормальной формы"}
                    definition={"отношение, которое удовлетворяет всем нетривиальным зависимостям соединения"}
                    redefinition={["отношение, которое неприводимо", "отношение, которое не может быть подвергнуто дальнейшей декомпозиции без потерь"]}
                />,
                <Enumeration
                    name={"Основные цели проектирования реляционной базы на основе метода нормализации"}
                    items={[
                        <Statement
                            name={"Цель проектирования реляционной базы данных"}
                            statement={"Избежать избыточности хранения данных"}
                        />,
                        <Statement
                            name={"Цель проектирования реляционной базы данных"}
                            statement={"Устранить аномалии отношений"}
                        />
                    ]}
                    isInvisibleChildrenType={true}
                    isInvisibleChildrenName={true}
                />
            ]
        },
        {
            number: "2",
            name: "Семантическая модель Entity-Relationship (ER)",
            items: [
                <Enumeration
                    name={"Ограничения реляционной модели"}
                    items={[
                        <Statement
                            name={"Ограничение реляционной модели"}
                            statement={"Модель не обеспечивает достаточных средств для представления смысла данных"}
                        />,
                        <Statement
                            name={"Ограничение реляционной модели"}
                            statement={"Модель не представляет какие-либо формализованные средства для представления веутренних зависимостей"}
                        />
                    ]}
                    isInvisibleChildrenType={true}
                    isInvisibleChildrenName={true}
                />,
                <Statement
                    name={"Основное назначение семантической модели данных"}
                    statement={"Обеспечение возможности выражения семантических данных"}
                />,
                <Enumeration
                    name={"Достоинства семантической модели"}
                    items={[
                        <Statement
                            name={"Достоинство семантической модели"}
                            statement={"Построение мощной и наглядной концептуальной схемы БД позволяет более полно оценить специфику моделируемой предметной области и избегать возможных ошибок на стадии проектирования схемы реляционной БД"}
                        />,
                        <Statement
                            name={"Достоинство семантической модели"}
                            statement={"На этапе семантического моделирования производится важная документация, которая может оказаться очень полезной не только при проектировании схемы реляционной БД, но и при эксплуатации, сопровождении и развитии уже заполненной БД"}
                        />
                    ]}
                    isInvisibleChildrenType={true}
                    isInvisibleChildrenName={true}
                />,
                <Definition
                    term={"Сущность"}
                    definition={"реальный или прдеставляемы объект, информация о котором должна сохраняться и быть доступной"}
                    tags={["ER-модель"]}
                />,
                <Definition
                    term={"Связь сущностей"}
                    definition={"графически изображаемая ассоциация, устанавливаемая между двумя типами сущностей"}
                    tags={["ER-модель"]}
                />,
                <Definition
                    term={"Атрибут сущености"}
                    definition={"любая деталь, которая служит для уточнения, индефикации, классификации, числовой характеристики или выражения состояния сущности."}
                    tags={["ER-модель"]}
                />,
                <Enumeration
                    name={"Уникальные индефикаторы сущности"}
                    items={[
                        <Statement
                            name={""}
                            statement={"Атрибут (или комбинация атрибутов)"}
                        />,
                        <Statement
                            name={""}
                            statement={"Связь (или комбинация связей)"}
                        />,
                        <Statement
                            name={""}
                            statement={"Комбинация атрибутов и связей"}
                        />,
                    ]}
                    isInvisibleChildrenType={true}
                    isInvisibleChildrenName={true}
                    tags={["ER-модель"]}
                />,
                <Enumeration
                    name={"Элементы модели"}
                    items={[
                        <Definition
                            term={"Подтип сущности"}
                            definition={""}
                            tags={["ER-модель"]}
                            isTodo={true}
                        />,
                        <Definition
                            term={"Супертип сущности"}
                            definition={"тип сущности, на основе которого определяются подтипы"}
                            tags={["ER-модель"]}
                        />,
                        <Definition
                            term={"Уточняемая степень связи сущностей"}
                            definition={""}
                            tags={["ER-модель"]}
                            isTodo={true}
                        />,
                        <Definition
                            term={"Взаимно-исключая связь сущностей"}
                            definition={"связь, при которой может существовать только один экземпляр связи из заданного набора связей"}
                            tags={["ER-модель"]}
                        />,
                        <Definition
                            term={"Каскадное удаление экземпляров сущности"}
                            definition={""}
                            tags={["ER-модель"]}
                            isTodo={true}
                        />,
                        <Definition
                            term={"Домен сущности"}
                            definition={"Домен сущности"}
                            tags={["ER-модель"]}
                            isTodo={true}
                        />
                    ]}
                    tags={["ER-модель"]}
                    isInvisibleChildrenType={true}
                    isInvisibleChildrenName={true}
                    isInvisibleChildrenTags={true}
                />,
                <Algorithm
                    name={"Преобразование ER-модели к реляционной модели"}
                    items={[
                        "Имя сущности становится именем таблицы",
                        "Каждый атрибут становится столбцом таблицы",
                        "Экземпляр типа сущности становится строкой таблицы",
                        "Уникальный индефикатор сущности становится первичным ключом таблицы",
                        "Связи «многие к одному» (и «один к одному») становятся внешними ключами"
                    ]}
                    tags={["ER-модель", "Реляционная модель"]}
                    isTodo={true}
                />
                //TODO
                /*
                <Definition
                     term={""}
                     definition={""}
                 />,
                <Statement
                    name={""}
                    statement={""}
                />,
                <Enumeration
                    name={""}
                    items={[
                    ]}
                    isInvisibleChildrenType={true}
                    isInvisibleChildrenName={true}
                    tags={["ER-модель"]}
                />,
                 */
            ],

        },
        {
            number: "3",
            name: "Диаграммы классов языка UML",
            items: []
        },
        {
            number: "4",
            name: "Методы управления транзакциями",
            items: []
        },
        {
            number: "5",
            name: "Обработка запросов",
            items: []
        },
        {
            number: "6",
            name: "Методы оптимизации запросов",
            items: []
        },
        {
            number: "7",
            name: "Средства журнализации и восстановления баз данных",
            items: []
        },
        {
            number: "8",
            name: "Безопасность систем баз данных",
            items: [
                <Definition
                    term={"Безопасность данных"}
                    definition={"состояние защищённости, при котором обеспечивается конфиденциальность, доступность и целостность"}
                />,
                <Definition
                    term={"Конфиденциальность данных"}
                    definition={"состояние, которое обеспечивает доступ к данным только сонкционированным пользователям"}
                />,
                <Definition
                    term={"Целостность данных"}
                    definition={"состояния, которое исключает несанкционированное изменение структуры и содержания данных"}
                />,
                <Definition
                    term={"Доступность данных"}
                    definition={"состояние, которое обеспечивает доступ к данным к данным, санкционированным пользователям, по их первому требованию"}
                />,
                <Statement
                    name={"Достижение конфиденциальности данных"}
                    statement={"Предоставление доступа к данным с наименьшими привелениями исходя из принципа минимальной необхожимости"}
                />,
                <Enumeration
                    name={"Меры обеспечения конфиденциальности данных"}
                    items={[
                        "Классификация данных, предназначаемых для публичного или внутреннего пользования",
                        "Шифрование данных"
                    ]}
                />,
                <Enumeration
                    name={"Меры обеспечение целостности данных"}
                    items={[
                        "Ограничение круга лиц с правами на изменения лишь тем, кому такой досуп необходим для выполнения служебных обязаностей",
                        "Применение транзакций"
                    ]}
                />,
                <Enumeration
                    name={"Факторы, влияющие на доступность данных"}
                    items={[
                        "DoS-атаки",
                        "Атаки программ-вымогателей",
                        "Саботаж",
                        "Человеческие ошибки",
                        "Отказ в обслуживании в результате превышения допустимой мощности или недостатка ресурсов оборудования"
                    ]}
                />,
                <Task
                    task={"Защита от несанкционированного доступа к данным за счёт уязвимостей в клиентских приложениях БД"}
                    decision={"Администрирование прав доступа"}
                />,
                <Task
                    task={"Защита от потери данных вследствии аппаратных или программных сбоев серверов БД случайного или преднамеренного характера"}
                    decision={"Резервное копирование данных"}
                />,
                <Task
                    task={"Защита от остановки или значительного снижения производительности сервера БД, вызванное большим количеством активных пользователей или преднамеренными атаками"}
                    decision={"Репликация данных, масштабирование БД"}
                />,
                <Task
                    task={"Защита от снижения производительности сервера БД, вызванное преднамеренными действиясм уполномоченных пользователей"}
                    decision={"Средства мониторинга и протоколирования событий"}
                />,
                <Task
                    task={"Защита от беспрепятственного доступа к данным в случа успешной атаки или хищения"}
                    decision={"Шифрование критических данных"}
                />,
                <Enumeration
                    name={"Преимущества СУБД в обеспечении безопасность данных"}
                    items={[
                        "Аутентификация и авторизация пользователей",
                        "Криптографическая защита БД",
                        "Резервное копирование данных",
                        "Репликация и балансировка нагрузки",
                        "Аудит событий безопасности БД",
                        "Модернизация системного и прикладного ПО"
                    ]}
                />,
                <Definition
                    term={"Аунтефикация"}
                    definition={"процедура проверки подлинности пользователя"}
                />,
                <Definition
                    term={"Авторизация"}
                    definition={"процедура предоставления пользователю определённых ресурсов и прав на их использование"}
                />,
                <Definition
                    term={"Шифрование"}
                    definition={"обратимое преобразование информации в целях сокрытия от неавторизованных лиц с предоставлением в это же время авторизованным пользователям досупа к ней"}
                />,
                <Enumeration
                    name={"Защищенная СУБД должна уметь шифровать"}
                    items={[
                        "Хранящиеся в ней данные (включая служебную информацию)",
                        "Исходный код запросов",
                        "Хранимых процедур и триггеров",
                        "Данные, передаваемые к другим компьютерам по незащищенным каналам"
                    ]}
                />,
                <Definition
                    term={"Репликакация баз данных"}
                    definition={"копирование изменений данных с главного сервера баз данных на одном или нескольких зависимых серверах"}
                />,
                <Enumeration
                    name={"Преимущества репликации"}
                    items={[
                        "Масштабиру"
                        //TODO
                    ]}
                />,
                /*
                <Definition
                     term={""}
                     definition={""}
                 />,
                <Statement
                    name={""}
                    statement={""}
                />,
                <Enumeration
                    name={""}
                    items={[
                    ]}
                />,
                <Task
                    task={""}
                    decision={""}
                />,
                 */
            ]
        },
    ];

    return (
        <span>
            {data.map((value) => (
                <Lecture
                    number={value.number}
                    name={value.name}
                    items={value.items}
                />
            ))}
        </span>
    )
}

export default Databases4SemesterLectures;