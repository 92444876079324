import React from "react";

interface AbbreviationProps {
    abbreviation: string,
    fullForm: any
}

const Abbreviation: React.FC<AbbreviationProps> = (props) => {
    return (
        <div>
            <b>Сокращение</b>. <i>{props.abbreviation}</i> - {props.fullForm}.
        </div>
    )
}

export default Abbreviation;