import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Logo: React.FC = () => {
    return (
        <Container className="text-center my-4">
            <Row>
                <Col>
                    <img src="/logo.png" alt="Logo" className="img-fluid" style={{ maxWidth: '200px' }} />
                    <h1 className="mt-3">Прикладная математика и информатика</h1>
                </Col>
            </Row>
        </Container>
    );
};

export default Logo;
