import React from "react";
import {Card} from "react-bootstrap";
import Definition from "../mathobjects/Definition";
import Abbreviation from "../mathobjects/Abbreviation";
import Enumeration from "../mathobjects/Enumeration";
import {MathRenderer} from "../mathobjects/MathRenderer";
import Tickets, {StatusReadyType} from "../components/Tickets";
import Display from "../mathobjects/Display";
import Formula from "../mathobjects/Formula";

const Complan3SemesterQuestions: React.FC = (props) => {

    const data = [
        {
            number: 1,
            status: StatusReadyType.IN_PROGRESS,
            header: "Комплексные числа и действия над ними. Модуль и аргумент комплексного числа. Алгебраическая, тригонометрическая и показательная формы комплексного числа. Возведение в степень и извлечение корня степени n из комплексного числа. Комплексно-сопряжённое число.",
            content:
                <div>
                    <Definition
                        term={<span>Математическое число</span>}
                        definition={"число, которое представлено в виде комбинации действительного и мнимого числа"}
                        display={<MathRenderer mathContent={"z"}/>}
                    />
                    <Definition term={"Модуль комплексного числа"}
                                definition={"расстояние от начала координат до точки, которая изображает комплексное число на комплексной плоскости"}
                                display={<MathRenderer mathContent={"r=|z|"}/>}
                    />
                    <Definition
                        term={"Аргумент комплексного числа"}
                        definition={"угол между положительным направлением оси ординат и радиус вектором точки комплексного числа"}
                        display={<MathRenderer mathContent={"\\varphi=arg(z)"}/>}
                    />
                    <Definition
                        term={"Комплексно-сопряжённое число"}
                        definition={"комплексное число, которое получено изменением знака мнимой части"}
                        display={<MathRenderer mathContent={"\\overline{z}"}/>}/>
                    <Display
                        term={"Алгебраическая форма комплексного числа"}
                        display={
                            <span>
                                <MathRenderer mathContent={"z=x+iy"}/>, где <MathRenderer mathContent={"x=Re(z)"}/> - действительная часть комплексного числа, <MathRenderer
                                mathContent={"y=Im(z)"}/> - мнимая часть комплексного числа
                            </span>
                        }
                    />
                    <Display
                        term={"Тригонометрическая форма комплексного числа"}
                        display={<MathRenderer mathContent={"z=r(cos(\\varphi) + i sin(\\varphi))"}/>}
                    />
                    <Display
                        term={"Показательная форма комплексного числа"}
                        display={<MathRenderer mathContent={"z=re^{i\\varphi}"}/>}
                    />
                    <Formula
                        name={"Сумма комплексных чисел"}
                        formula={<MathRenderer mathContent={"z_1+z_2=(x_1+x_2 )+i(y_1+y_2 )"}/>}
                        decoding={null}
                    />
                    <Formula
                        name={"Разность комплексных чисел"}
                        formula={<MathRenderer mathContent={"z_1-z_2=(x_1-x_2 )+i(y_1-y_2 )"}/>}
                        decoding={null}
                    />
                    <Formula
                        name={"Произведение комплексных чисел в алгебраической форме"}
                        formula={<MathRenderer mathContent={"z_1 z_2=(x_1 x_2-y_1 y_2 )+i(x_1 y_2+y_1 x_2 )"}/>}
                        decoding={null}
                    />
                    <Formula
                        name={"Произведение комплексных чисел в тригонометрической форме"}
                        formula={<MathRenderer
                            mathContent={"z_1 z_2=r_1 r_2 (cos(\\varphi_1+\\varphi_2 )+i sin(\\varphi_1+\\varphi_2 ) )"}/>}
                        decoding={null}
                    />
                    <Formula
                        name={"Произведение комплексных чисел в показательной форме"}
                        formula={<MathRenderer mathContent={"z_1 z_2=r_1 r_2 e^i(\\varphi_1+\\varphi_2 )"}/>}
                        decoding={null}
                    />
                    <Formula
                        name={"Частное двух комплексных чисел в алгебраической форме"}
                        formula={<MathRenderer
                            mathContent={"\\frac{z_1}{z_2} =\\frac{(x_1 x_2+y_1 y_2 )+i(y_1 x_2-x_1 y_2 )}{x_2^2+y_2^2}"}/>}
                        decoding={
                            <span>
                            Где <MathRenderer mathContent={"z_2\\ne0"}/>
                        </span>}
                    />
                    <Formula
                        name={"Частное двух комплексных чисел в тригонометрической форме"}
                        formula={<MathRenderer
                            mathContent={" \\frac{z_1}{z_2} =\\frac{r_1}{r_2}  (cos(\\varphi_1-\\varphi_2 )+i sin(\\varphi_1-\\varphi_2 ) )"}/>}
                        decoding={
                            <span>
                            Где <MathRenderer mathContent={"z_2\\ne0"}/>
                        </span>}
                    />
                    <Formula
                        name={"Частное двух комплексных чисел в показательной форме"}
                        formula={<MathRenderer
                            mathContent={"\\frac{z_1}{z_2} =\\frac{r_1}{r_2} ⋅e^i(\\varphi_1-\\varphi_2 )"}/>}
                        decoding={
                            <span>
                            Где <MathRenderer mathContent={"z_2\\ne0"}/>
                        </span>}
                    />
                    <Formula
                        name={"Формула Муавра. Возведение в степень комплексного числа в тригонометрической форме"}
                        formula={<MathRenderer mathContent={"z^n=r^n (cos⁡n\\varphi+i sin⁡n\\varphi )"}/>}
                        decoding={
                            <span>
                            Где <MathRenderer mathContent={"n∈N"}/>
                        </span>}/>
                    <Formula
                        name={"Возведение в степень комплексного числа в показательной форме"}
                        formula={<MathRenderer mathContent={"z^n=r^n e^inφ"}/>}
                        decoding={null}
                    />
                    <Formula
                        name={"Извлечение корня комплексного числа в тригонометрической форме"}
                        formula={<MathRenderer mathContent={"\\sqrt[n]{z} =\\sqrt[n]{r}(cos((φ+2πk)/n)+i sin((φ+2πk)/n) )"}/>}
                        decoding={
                            <span>
                            Где <MathRenderer mathContent={"k=0,1,2,…,n-1."}/>
                        </span>}
                    />
                    <Formula
                        name={"Извлечение корня комплексного числа в показательной форме"}
                        formula={<MathRenderer mathContent={"\\sqrt[n]{z}=\\sqrt[n]{r}⋅e^i((φ+2πk)/n)"}/>}
                        decoding={
                            <span>
                            Где <MathRenderer mathContent={"k=0,1,2,…,n-1."}/>
                        </span>}
                    />
                </div>
        },
        {
            number: 2,
            status: StatusReadyType.TODO,
            header: "Модели данных: иерархическая, сетевая, инвертированных таблиц.",
            content:
                <div>
                    <Abbreviation abbreviation={"БД"} fullForm={"база данных"}/>
                    <Definition term={"Система управления базами данных"} definition={"система"} />
                    <Abbreviation abbreviation={"СУБД"} fullForm={"система управления базами данных"}/>
                    <Enumeration name={"Требования к СУБД"} items={["требование 1", "требование 2"]}/>
                    <MathRenderer mathContent={"\\int_a^b x dx"}/> а дальше просто текст
                </div>
        }
    ]


    return (
        <Card>
            <Card.Header>
                4 семестр. Комплан. Список вопросов.
            </Card.Header>
            <Card.Body>
                <Tickets data={data}/>
            </Card.Body>
        </Card>
    )
}

export default Complan3SemesterQuestions;