import React from "react";

interface TaskProps {
    name?: any
    task: any
    decision: any
}

const Task: React.FC<TaskProps> = (props) => {
    return (
        <div>
            <div>
                <b>Задача</b>
                {". "}
                {props.name && (
                    <span>
                    <i>
                        {props.name}
                    </i>
                        {": "}
                </span>
                )}
                {props.task}
                {". "}
            </div>
            <div style={{paddingLeft: '2em'}}>
                <b>Решение</b>
                {". "}
                {props.decision}
            </div>
        </div>
    );
}

export default Task;