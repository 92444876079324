import React from "react";

interface FormulaProps {
    name: any,
    formula: any
    decoding: any
}

const Formula: React.FC<FormulaProps> = (props) => {
    return (
        <div>
            <b>Формула</b>. <i>{props.name}</i>: {props.formula}. {props.decoding != null && (
            <span>{props.decoding}</span>)}
        </div>
    )
}

export default Formula;