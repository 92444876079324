import React from "react";
import {Accordion} from "react-bootstrap";

export enum StatusReadyType {
    TODO,
    IN_PROGRESS,
    DONE
}

interface TicketsProps {
    data: any[]
}

const Tickets: React.FC<TicketsProps> = (props) => {
    const getHeaderStyle = (status: StatusReadyType | any) => {
        switch (status) {
            case StatusReadyType.TODO:
                return {
                    backgroundColor: "#4A90E2",
                    color: "#000000"
                };
            case StatusReadyType.IN_PROGRESS:
                return {
                    backgroundColor: "#F5A623",
                    color: "#000000"
                };
            case StatusReadyType.DONE:
                return {
                    backgroundColor: "#7ED321",
                    color: "#000000"
                };
            default:
                return {
                    backgroundColor: "#CCCCCC",
                    color: "#000000"
                };
        }
    };


    return (
        <Accordion>
            {props.data.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Button style={getHeaderStyle(item.status)}>
                        <b>
                            {`№${item.number}. ${item.header}`}
                        </b>
                    </Accordion.Button>
                    <Accordion.Body>
                        {item.content}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    )
}

export default Tickets;