import React from "react";
import {Accordion, Card, Container} from "react-bootstrap";
import Definition from "../mathobjects/Definition";
import Abbreviation from "../mathobjects/Abbreviation";
import Enumeration from "../mathobjects/Enumeration";
import {Property} from "csstype";
import {MathRenderer} from "../mathobjects/MathRenderer";
import Tickets from "../components/Tickets";

enum StatusReadyType {
    TODO,
    IN_PROGRESS,
    DONE
}

const Databases3SemesterQuestions: React.FC = (props) => {

    const data = [
        {
            number: 1,
            status: StatusReadyType.IN_PROGRESS,
            header: "БД, СУБД, требования к СУБД.",
            content:
                <div>
                    <Definition term={"База данных"} definition={"таблица"} />
                    <Abbreviation abbreviation={"БД"} fullForm={"база данных"}/>
                    <Definition term={"Система управления базами данных"} definition={"система"} />
                    <Abbreviation abbreviation={"СУБД"} fullForm={"система управления базами данных"}/>
                    <Enumeration name={"Требования к СУБД"} items={["требование 1", "требование 2"]}/>
                </div>
        },
        {
            number: 2,
            status: StatusReadyType.TODO,
            header: "Модели данных: иерархическая, сетевая, инвертированных таблиц.",
            content:
                <div>
                    <MathRenderer mathContent={"\\int_a^b x dx"}/> а дальше просто текст
                </div>
        },
        {
            number: 3,
            status: StatusReadyType.DONE,
            header: "Реляционная модель: основные понятия, манипалирование данными, целостность.",
            content: <div> 33 </div>
        },
        {
            number: 4,
            status: null,
            header: "Модель данных SQL: манипалирование данными, цолостность.",
            content: <div> 44 </div>
        },
        {
            number: 5,
            status: StatusReadyType.TODO,
            header: "Алгебра A, полнота и избыточность алгебры A.",
            content: <div></div>
        },
        {
            number: 6,
            status: StatusReadyType.TODO,
            header: "Типы хранения данных, дискоориентированные СУБД.",
            content: <div></div>
        },
        {
            number: 7,
            status: StatusReadyType.TODO,
            header: "Страницы, архитектура хранения страниц.",
            content: <div></div>
        },
        {
            number: 8,
            status: StatusReadyType.TODO,
            header: "Буферный пул, таблица страниц, директория страниц",
            content: <div></div>
        },
        {
            number: 9,
            status: StatusReadyType.TODO,
            header: "Политика замены буфера: FIFO, LRU, CLOCK.",
            content: <div></div>
        },
        {
            number: 10,
            status: StatusReadyType.TODO,
            header: "Индексы, упорядоченные индексы; плотный индекс.",
            content: <div></div>
        },
        {
            number: 11,
            status: StatusReadyType.TODO,
            header: "Вторичные и многоуровневые индексы, вставка и удаление.",
            content: <div></div>
        },
        {
            number: 12,
            status: StatusReadyType.TODO,
            header: "Файл индекса в виде B/B+ дерева; вставка и удаление",
            content: <div></div>
        },
        {
            number: 13,
            status: StatusReadyType.TODO,
            header: "Bitmap индексы; хеш-файлы",
            content: <div></div>
        },
        {
            number: 14,
            status: StatusReadyType.TODO,
            header: "Хеш-функции, коллизии; хеш-таблицы",
            content: <div></div>
        },
        {
            number: 15,
            status: StatusReadyType.TODO,
            header: "Схемы статического хеширования",
            content: <div></div>
        },
        {
            number: 16,
            status: StatusReadyType.TODO,
            header: "NoSQL базы данных",
            content: <div></div>
        }
    ]

    const getHeaderStyle = (status: StatusReadyType | any) => {
        switch (status) {
            case StatusReadyType.TODO:
                return {
                    backgroundColor: "#4A90E2",
                    color: "#000000"
                };
            case StatusReadyType.IN_PROGRESS:
                return {
                    backgroundColor: "#F5A623",
                    color: "#000000"
                };
            case StatusReadyType.DONE:
                return {
                    backgroundColor: "#7ED321",
                    color: "#000000"
                };
            default:
                return {
                    backgroundColor: "#CCCCCC",
                    color: "#000000"
                };
        }
    };


    return (
        <Card>
            <Card.Header>
                3 семестр. Базы данных. Список вопросов.
            </Card.Header>
            <Card.Body>
                <Tickets data={data}/>
            </Card.Body>
        </Card>
    )
}

export default Databases3SemesterQuestions;