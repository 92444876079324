import {Badge, OverlayTrigger} from "react-bootstrap";
import React from "react";

interface TagDisplayProps {
    tags: any[]
}

const TagDisplay: React.FC<TagDisplayProps> = (props) => {
    return (
        <span>
            {props.tags.length > 1 ? (
                <OverlayTrigger
                    placement="auto-start"
                    overlay={
                        <span>
                            {props.tags.slice(1).reverse().map((value, index) => (
                                <div key={index}>
                                    <Badge bg="secondary">{value}</Badge>
                                </div>
                            ))}
                        </span>
                    }
                >
                    <span>
                        <Badge bg="secondary">
                            <span>
                                {props.tags[0]}
                                {" "}
                                <Badge bg="dark">{props.tags.length}</Badge>
                            </span>
                        </Badge>
                    </span>
                </OverlayTrigger>
            ) : (
                <Badge bg="secondary">{props.tags[0]}</Badge>
            )}
            {". "}
        </span>
    )
}

export default TagDisplay;