import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Logo from './components/Logo';
import SubjectList from './components/SubjectList';
import Databases3SemesterQuestions from "./pages/Databases3SemesterQuestions";
import Complan3SemesterQuestions from "./pages/Complan4SemesterQuestions";
import Databases4SemesterQuestions from "./pages/Databases4SemesterQuestions";
import Databases4SemesterLectures from "./pages/Databases4SemesterLectures";
import {ProgressBar} from "react-bootstrap";

const App: React.FC = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 99) {
                    return 0;  // Увеличение прогресса
                }
                return prevProgress + 1; // Увеличение прогресса
            });
        }, 100);  // Обновление каждые 1 секунду

        return () => clearInterval(interval);  // Очистка интервала при размонтировании компонента
    }, []);

    const subjects = [
        'Функциональный анализ --- экзамен',
        'Теория вероятностей и математическая статистика --- экзамен',
        'Математические методы механики --- экзамен',
        'Алгоритмы и теория сложности --- экзамен',
        'Уравнения с частными производными --- экзамен',
        'Численные методы --- зачёт',
        'Основы математического моделирования --- зачёт',
        'Программные среды эффективных вычислений --- зачёт',
        'Электродинамика и распространение радиоволн / Радиолокационные системы --- зачёт'
    ];

    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <div style={{padding: '20px'}}>
                        <Logo/>
                        <ProgressBar now={progress} label={`${progress}%`} />
                        <Databases4SemesterLectures/>
                        <SubjectList subjects={subjects}/>
                    </div>
                }/>
                <Route path="/contacts" element={<Logo/>}/>
                <Route path="/database" element={<Databases3SemesterQuestions/>}/>
                <Route path={"/complan"} element={<Complan3SemesterQuestions/>}/>
                <Route path={"/s"} element={<Databases4SemesterLectures/>}/>
            </Routes>
        </Router>
    );
};

export default App;
