import React from "react";

interface StatementProps {
    name?: any
    statement: any
    isInvisibleType?: boolean
    isInvisibleName?: boolean
}

const Statement: React.FC<StatementProps> = (props) => {
    return (
        <div>
            {!props.isInvisibleType && (
                <span>
                    <b>Утверждение</b>.
                    {" "}
                </span>
            )}
            {!props.isInvisibleName && props.name && (
                <span>
                    <i>
                        {props.name}
                    </i>
                    {": "}
                </span>
            )}
            {props.statement}.
        </div>
    )
}

export default Statement;