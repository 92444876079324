import React from 'react';
import {Container, Row, Col, ListGroup} from 'react-bootstrap';

interface SubjectListProps {
    subjects: string[];
}

const SubjectList: React.FC<SubjectListProps> = ({subjects}) => {
    return (
        <Container>
            <Row>
                <Col>
                    <Container>
                        <h2 className="my-4">Предметы пятого семестра</h2>
                    </Container>
                    <ListGroup>
                        {subjects.map((subject, index) => (
                            <ListGroup.Item key={index}>{subject}</ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col>
                    <Container>
                        <h2 className="my-4">Предметы четвёртого семестра</h2>
                    </Container>
                    <ListGroup>
                        {subjects.map((subject, index) => (
                            <ListGroup.Item key={index}>{subject}</ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                Б
            </Row>
        </Container>
    );
};

export default SubjectList;
