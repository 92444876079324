import React from "react";
import TagDisplay from "./TagDisplay";

interface EnumerationProps {
    name: string,
    items: any[],
    tags?: any[]

    isInvisibleChildrenType?: boolean
    isInvisibleChildrenName?: boolean
    isInvisibleChildrenTags?: boolean

    isInvisibleTags?: boolean
}

const Enumeration: React.FC<EnumerationProps> = (props) => {
    return (
        <div>
            <b>Перечисление</b>
            {". "}
            {!props.isInvisibleTags && props.tags && (
                <TagDisplay tags={props.tags}/>
            )}
            <i>{props.name}</i>:<br/>
            <ul style={{margin: 0}}>
                {props.items.map((value, index) => {
                        if (typeof value === 'string') {
                            return (
                                <li key={index}>
                                    {value}
                                </li>
                            );
                        } else {
                            return (
                                <li key={index}>
                                    {React.cloneElement(value, {
                                        isInvisibleType: props.isInvisibleChildrenType,
                                        isInvisibleName: props.isInvisibleChildrenName,
                                        isInvisibleTags: props.isInvisibleChildrenTags
                                    })}
                                </li>
                            );
                        }
                    }
                )}
            </ul>
        </div>
    )
}

export default Enumeration;