import React from "react";

interface DisplayProps {
    term: any,
    display: any
}

const Display: React.FC<DisplayProps> = (props) => {
    return (
        <div>
            <b>Отображение</b>. <i>{props.term}</i>: {props.display}.
        </div>
    )
}

export default Display;