import React from "react";
import TagDisplay from "./TagDisplay";

interface StatementProps {
    name: any
    items: any[]
    tags?: any[]

    isInvisibleTags?: boolean

    isTodo?: boolean
}

const Algorithm: React.FC<StatementProps> = (props) => {
    return (
        <div>
            <b style={{
                backgroundColor: props.isTodo ? "rgba(147,197,255,0.82)" : undefined,
                borderRadius: "8px",
            }}>
                Алгоритм
            </b>
            {". "}
            {!props.isInvisibleTags && props.tags && (
                <TagDisplay tags={props.tags}/>
            )}
            <i>{props.name}</i>
            {":"}
            <ol style={{margin: 0}}>
                {props.items.map((value) => (

                    <li>
                        {value}
                    </li>

                ))}
            </ol>
        </div>
    )
}

export default Algorithm;