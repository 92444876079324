import React from "react";
import {Card} from "react-bootstrap";


interface LectureProps {
    number: string
    name: string
    items: any[]
}

const Lecture: React.FC<LectureProps> = (props) => {
    return (
        <Card>
            <Card.Header >
                Лекция №{props.number}
                <br/>
                {props.name}
            </Card.Header>
            <Card.Body>
                {props.items.map((value, index) => (
                    value
                ))}
            </Card.Body>
        </Card>
    )
}

export default Lecture